@keyframes scroll-down {
	0% {
		opacity: 0;

		top: 15%;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		top: 60%;
	}
}

#Travel {
	margin-top: 120px;
	margin-bottom: 0;
}

#Viewer {
	overflow: none;
}

ul li:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

li {
	font-size: 22px;
}

h3 {
	font-size: 18px;
	text-align: center;
}

span {
	display: none;
}
.search {
	font-size: 26px;
	position: relative;
	top: 5px;
}
.title_mobile {
	position: relative;
	bottom: 5px;
}
.menu_mobile li {
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.home__scroll {
	margin: 0 auto -800px;
}

.home__scroll-button {
	color: #fff;
	transition: 0.3s;
	display: flex;
	justify-content: center;
}
.home__scroll-mouse {
	margin-top: 0.5rem;
	height: 2.5rem;
	width: 2rem;
	color: translucid;
	position: absolute;
	top: 90%;
	border: 1.8px solid;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0 0px 4px -2.5px #fff;
	text-shadow: 0 0 #fff;
	overflow: hidden;
}

.home__scroll-mouse::before,
.home__scroll-mouse::after {
	content: "";
	position: absolute;
	top: 15%;
	border: 1.8px solid #fff;
	height: 0.7rem;
	width: 0.7rem;
	transform: translate(-50%, -50%) rotate(45deg);
	border-top: transparent;
	border-left: transparent;
	box-shadow: 1px 0px 8px -2.5px #fff;
	animation: scroll-down 1.05s ease-in-out infinite;
}

.home__scroll-mouse:hover ~ .home__scroll-name {
	transition: all 1s;

	box-shadow: 1px 1px 8px 4px #fff;
}

.home__scroll-name {
	display: none;
	font-size: 20px;
	color: #fff;
	font-weight: 20px;
}

.home__scroll-arrow {
	font-size: 1.3rem;
}

.background_nav {
	background-color: rgba(0, 0, 0, 0.25);
}

.services_group {
	display: flex;
	justify-content: center;
	margin: 0px auto;
	color: rgb(255, 255, 255);
	font-size: 16px;
	text-align: center;
}

.title-wrap {
	margin-bottom: -100px;
	color: aliceblue;
}

.lg-title {
	font-size: 28px;
}

.services_first,
.services_second,
.services_thirt {
	display: flex;
	justify-content: center;
	flex-direction: column;
	bottom: 100px;
	margin: 0 auto;
	color: #fff;
	text-align: center;
}

.hotel_icon,
.airplane_icon,
.map__icon {
	margin: 0 auto auto;
	font-size: 3.5rem;
}

.fly__icon {
	font-size: 20px;
	position: relative;
	left: -15px;
}

.arrowInside,
.arrowInside1,
.arrowInside2 {
	position: relative;
	left: 55px;
	bottom: 0px;
	width: 100px;
	height: 100px;
}

.arrowInside1 {
	left: 60px;
}

.arrowInside2 {
	left: 55px;
}

.arrowInside:hover,
.arrowInside1:hover,
.arrowInside2:hover {
	color: rgba(0, 255, 196, 0.8);
	transition: 1s all;
	text-shadow: 10px 10px #fff;
	transform: rotate(10deg);
}

.arrowInside span,
.arrowInside1 span,
.arrowInside2 span {
	position: absolute;
	bottom: 32px;
	width: 200px;
	right: -15px;
	transform: rotate(0deg);
	text-shadow: 0px 0px;
}

.arrowInside:hover span,
.arrowInside1:hover span,
.arrowInside2:hover span {
	transform: rotate(-10deg);
}

.icon_text {
	display: none;
}

#Places img {
	width: 100%;
	transition: 0.5s all ease-in-out;
}

#Places .selectCard1:hover img,
#Places .selectCard2:hover img,
#Places .selectCard3:hover img {
	transform: scale(1.5);
}
div .selectCard,
div .selectCard1,
div .selectCard2,
div .selectCard3,
div .selectCard4,
div .selectCard5 {
	overflow: hidden;
}
@media screen and (min-width: 740px) {
	h3 {
		font-size: 28px;
	}
	span {
		display: inherit;
		font-size: 20px;
		margin-top: 10px;
	}

	span:nth-child(2n) {
		margin-top: 0px;
	}

	.fly__icon {
		font-size: 27px;
	}
	.lg-title {
		font-size: 50px;
	}
	.hotel_icon,
	.airplane_icon,
	.map__icon {
		font-size: 9rem;
		margin-bottom: 1rem;
	}
	.services_first,
	.services_second,
	.services_thirt {
		position: relative;
		bottom: -40px;
	}
	.fly__icon {
		position: relative;
		left: 0px;
	}

	#Viewer {
		margin-bottom: 0px;
		max-width: initial;
		overflow: hidden;
	}
}

@media screen and (min-width: 1240px) {
	span {
		font-size: 24px;
	}

	.arrowInside:hover a .icon_text,
	.arrowInside1:hover a .icon_text,
	.arrowInside2:hover a .icon_text {
		display: block;
	}

	.arrowInside,
	.arrowInside1,
	.arrowInside2 {
		position: relative;
		left: 140px;
		bottom: 10px;
		width: 100px;
		height: 100px;
	}

	.arrowInside1 {
		left: 110px;
	}

	.arrowInside2 {
		left: 120px;
	}

	.lg-title {
		margin-top: 200px;
	}

	.services_first,
	.services_second,
	.services_thirt {
		position: relative;
		bottom: -90px;
	}
}
